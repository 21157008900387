import React from 'react'
import styled, { css } from 'styled-components'
import { Flex } from 'components/Layout'
import { Tooltip } from 'components/Tooltip'
import { RADIUS, SPACE, COLOR_INTENT } from 'Theme'
import { ICON_SIZE, type IconProps } from '../Icon'
import { type ACTION_INTENT } from './constants'

export interface CollectionWidgetCardActionProps
  extends Omit<
    React.HTMLProps<HTMLButtonElement>,
    'size' | 'color' | 'children' | 'type' | 'disabled'
  > {
  icon: React.ElementType<IconProps>
  tooltip?: React.ReactNode
  intent: (typeof ACTION_INTENT)[keyof typeof ACTION_INTENT]
  isDisabled?: boolean
}

const Wrapper = styled(Flex)`
  ${({ backgroundColor, opacity }) => css`
    border-radius: ${RADIUS.PX_8};
    background-color: ${backgroundColor};
    padding: ${SPACE.PX_4};
    opacity: ${opacity};
  `};
`

export const CollectionWidgetCardAction: React.FC<
  CollectionWidgetCardActionProps
> = ({ tooltip, icon: Icon, intent, ...rest }) => {
  const { isDisabled } = rest
  const button = (
    <Wrapper
      opacity={isDisabled ? 0.5 : 1}
      backgroundColor={
        isDisabled
          ? COLOR_INTENT.COLLECTION_WIDGET_CARD.ACTION.DISABLED.BACKGROUND
          : intent.BACKGROUND
      }
    >
      <button {...rest} type="button">
        <Icon
          size={ICON_SIZE.PX_20}
          color={
            isDisabled
              ? COLOR_INTENT.COLLECTION_WIDGET_CARD.ACTION.DISABLED.ICON
              : intent.ICON
          }
        />
      </button>
    </Wrapper>
  )
  return tooltip ? (
    /* Set tooltipContainer to use default value in order to render tooltip in body instead inline */
    <Tooltip getTooltipContainer={undefined} body={tooltip}>
      {button}
    </Tooltip>
  ) : (
    button
  )
}
