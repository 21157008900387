import { rem } from 'polished'
import React from 'react'
import styled, { css } from 'styled-components'
import { Box, Flex } from 'components/Layout'
import { COLOR_INTENT } from 'Theme'

type CarouselPaginationDotProps = {
  className: string
  isAutoRotationEnabled?: boolean
  animationDuration?: number
  bulletActiveClass?: string
  slidesCount: number
  isDarkTheme?: boolean
}

export const BULLET_SIZE = rem(4)
export const BULLET_WRAPPER_SIZE_PX = 44
const BULLET_WRAPPER_SIZE = rem(BULLET_WRAPPER_SIZE_PX)
const INACTIVE_BULLET_DARK_THEME_OPACITY = 0.3
const INACTIVE_BULLET_LIGHT_THEME_OPACITY = 0.7

const Bullet = styled(Box)`
  display: block;
  width: ${BULLET_SIZE};
  height: ${BULLET_SIZE};
  margin: 0;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 100%;
  &:hover {
    opacity: 1;
  }
`

const Wrapper = styled(Flex)<{
  isAutoRotationEnabled: boolean
  animationDuration: number
  bulletActiveClass: string
  slidesCount: number
  isDarkTheme: boolean
  className
}>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  opacity: 1;
  cursor: pointer;
  background-color: transparent;
  min-width: ${BULLET_WRAPPER_SIZE};
  width: ${BULLET_WRAPPER_SIZE};
  height: ${BULLET_WRAPPER_SIZE};
  ${({ bulletActiveClass, isDarkTheme }) => css`
    ${Bullet} {
      width: ${BULLET_SIZE};
      height: ${BULLET_SIZE};
      transform: scale(1);
      background-color: ${isDarkTheme
        ? COLOR_INTENT.CAROUSEL_NAVIGATION.DARK.BULLET_COLOR.INACTIVE
        : COLOR_INTENT.CAROUSEL_NAVIGATION.LIGHT.BULLET_COLOR};
      opacity: ${isDarkTheme
        ? INACTIVE_BULLET_DARK_THEME_OPACITY
        : INACTIVE_BULLET_LIGHT_THEME_OPACITY};
    }
    &.${bulletActiveClass} {
      ${Bullet} {
        transform: scale(2);
        background-color: ${isDarkTheme
          ? COLOR_INTENT.CAROUSEL_NAVIGATION.DARK.BULLET_COLOR.ACTIVE
          : COLOR_INTENT.CAROUSEL_NAVIGATION.LIGHT.BULLET_COLOR};
        opacity: 1;
      }
    }
  `}
`

export const CarouselPaginationDot: React.FC<
  React.PWC<CarouselPaginationDotProps>
> = ({
  className,
  isAutoRotationEnabled = false,
  animationDuration = 5000,
  bulletActiveClass = 'swiper-pagination-bullet-active',
  slidesCount,
  isDarkTheme,
}) => (
  <Wrapper
    className={className}
    isAutoRotationEnabled={isAutoRotationEnabled}
    animationDuration={animationDuration}
    bulletActiveClass={bulletActiveClass}
    slidesCount={slidesCount}
    isDarkTheme={isDarkTheme}
  >
    <Bullet />
  </Wrapper>
)
