import { get } from 'lodash'
import { COMMISSION_TYPES, PRODUCTS_PAGE_SLUG } from 'constants/common'
import { ROUTE } from 'constants/routes'
import { type Collection } from 'types/graphql-generated'

export const getProductRoute = ({
  collections,
  expertSlug,
  slug,
  variantSlug,
  isPreview,
}: {
  collections?: Partial<Collection>[]
  expertSlug?: string
  slug?: string
  variantSlug?: string
  isPreview?: boolean
}): string | undefined => {
  if (!slug) {
    return undefined
  }

  const expertSlugValue =
    (expertSlug && expertSlug !== PRODUCTS_PAGE_SLUG) ||
    (collections?.length > 0 &&
      get(
        collections.find((collection) => collection.expert),
        ['expert', 'slug']
      ))
  if (expertSlugValue) {
    return ROUTE.SHOWROOM_COLLECTION_PRODUCT_DETAIL({
      slug: expertSlugValue,
      pid: slug,
      type: COMMISSION_TYPES.EXPERT_COLLECTION_PAGE,
      variantSlug,
    })
  }
  return isPreview
    ? ROUTE.SHOWROOM_PRODUCT_DETAIL_PREVIEW({ slug, variantSlug })
    : ROUTE.SHOWROOM_PRODUCT_DETAIL({ slug, variantSlug })
}
