import React from 'react'
import {
  type ColorProps,
  type SpaceProps,
  type TypographyProps,
} from 'styled-system'
import { Text } from 'components/Typography'
import { FONT_SIZE, FONT_STACK } from 'Theme'

export interface QATextProps extends SpaceProps, ColorProps, TypographyProps {}

export const QAText: React.FC<React.PWC<QATextProps>> = ({
  children,
  ...props
}) => (
  <Text
    fontFamily={FONT_STACK.SERIF}
    fontSize={FONT_SIZE.PX_16}
    as="p"
    {...props}
  >
    {children}
  </Text>
)
