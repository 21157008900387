import PropTypes from 'prop-types'
import React from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import {
  type ColorProps,
  type SpaceProps,
  type TypographyProps,
} from 'styled-system'
import { MarkdownRenderer } from 'components/MarkdownRenderer'
import { FONT_SIZE } from 'Theme'

export interface QAMarkdownProps
  extends SpaceProps,
    ColorProps,
    TypographyProps {
  source: string
}

export const QAMarkdown: React.FC<React.PWC<QAMarkdownProps>> = ({
  source,
  ...props
}) => (
  <MarkdownRenderer
    data-test-id={DATA_TEST_ID.OPENED_QA}
    fontSize={FONT_SIZE.PX_16}
    markdownSource={source}
    {...props}
  />
)

QAMarkdown.propTypes = {
  source: PropTypes.string,
}
