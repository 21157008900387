import React, { type MutableRefObject } from 'react'
import { Flex } from 'components/Layout'
import { SPACE } from 'Theme'
import { CarouselNavigationArrow } from './CarouselNavigationArrow'

type CarouselNavigationProps = {
  prevButtonRef?: MutableRefObject<any>
  nextButtonRef?: MutableRefObject<any>
  paginationRef: MutableRefObject<any>
  isDarkTheme?: boolean
  hasArrowsBackground?: boolean
  hasArrows?: boolean
  dotsWrapperStyles?: Record<string, string | number>
}

export const CarouselNavigation: React.FC<
  React.PWC<CarouselNavigationProps>
> = ({
  prevButtonRef,
  nextButtonRef,
  paginationRef,
  children,
  isDarkTheme = true,
  hasArrowsBackground = false,
  hasArrows = true,
  dotsWrapperStyles = {},
}) => (
  <Flex>
    {hasArrows && (
      <CarouselNavigationArrow
        ref={prevButtonRef}
        isDarkTheme={isDarkTheme}
        isLtr={false}
        hasBackground={hasArrowsBackground}
      />
    )}
    <Flex
      ref={paginationRef}
      px={{ MOBILE: SPACE.PX_8, TABLET: SPACE.PX_16 }}
      alignItems="center"
      position="relative"
      bottom="0 !important"
      justifyContent="center"
      {...dotsWrapperStyles}
    >
      {children}
    </Flex>
    {hasArrows && (
      <CarouselNavigationArrow
        ref={nextButtonRef}
        isDarkTheme={isDarkTheme}
        hasBackground={hasArrowsBackground}
        isLtr
      />
    )}
  </Flex>
)
