import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import {
  CarouselNavigationArrow,
  getArrowStickySide,
} from 'components/CarouselNavigation/CarouselNavigationArrow'
import { Flex } from 'components/Layout'
import { ReactRefPropType } from 'constants/common'
import { Z_INDEX } from 'Theme'

export const SWIPER_BUTTON_WIDTH = rem(40)
export const SWIPER_BUTTON_HEIGHT = rem(70)

const ArrowWrapper = styled(Flex)`
  position: absolute;
  height: fit-content;
  width: fit-content;
  align-items: start;
  z-index: ${Z_INDEX.CAROUSEL};
  ${({ isStickyToLeft }) => getArrowStickySide({ isStickyToLeft })};
  ${({ hasArrowsCentered }) => {
    if (hasArrowsCentered) {
      return {
        top: 0,
        bottom: 0,
        'margin-top': 'auto',
        'margin-bottom': 'auto',
      }
    }
    return {}
  }}
  .swiper-button-disabled {
    display: none;
  }
`

export const SwiperArrows = ({ prevButtonRef, nextButtonRef, ...rest }) => (
  <>
    <ArrowWrapper isStickyToLeft {...rest}>
      <CarouselNavigationArrow
        ref={prevButtonRef}
        className="swiper-no-swiping"
        aria-label="previous arrow button"
        hasBackground
        isDarkTheme
      />
    </ArrowWrapper>
    <ArrowWrapper {...rest}>
      <CarouselNavigationArrow
        ref={nextButtonRef}
        className="swiper-no-swiping"
        aria-label="previous arrow button"
        hasBackground
        isDarkTheme
        isLtr
      />
    </ArrowWrapper>
  </>
)

SwiperArrows.propTypes = {
  prevButtonRef: ReactRefPropType,
  nextButtonRef: ReactRefPropType,
}
