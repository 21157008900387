import { rem } from 'polished'
import RcTooltip from 'rc-tooltip'
import React, { useRef } from 'react'
import { useMedia } from 'react-use'
import styled from 'styled-components'
import { type LayoutProps, type SpaceProps } from 'styled-system'
import { Box } from 'components/Layout'
import {
  BOX_SHADOW,
  COLOR_INTENT,
  FONT_SIZE,
  FONT_WEIGHT,
  LINE_HEIGHT,
  RADIUS,
  Z_INDEX,
} from 'Theme'
import 'rc-tooltip/assets/bootstrap.css'

const BODY_MAX_WIDTH = rem(200)

const Wrapper = styled('div')`
  .rc-tooltip {
    z-index: ${({ zIndex }) => zIndex};
    font-size: ${FONT_SIZE.PX_12};
    font-weight: ${FONT_WEIGHT.MEDIUM};
    line-height: ${LINE_HEIGHT.L};
    opacity: 1;
  }

  .rc-tooltip-inner {
    background-color: ${COLOR_INTENT.TOOLTIP.BACKGROUND};
    border-radius: ${RADIUS.PX_6};
    box-shadow: ${BOX_SHADOW.ELEVATION_1};
  }

  .rc-tooltip-placement-top .rc-tooltip-arrow,
  .rc-tooltip-placement-topLeft .rc-tooltip-arrow,
  .rc-tooltip-placement-topRight .rc-tooltip-arrow {
    border-top-color: ${COLOR_INTENT.TOOLTIP.BACKGROUND};
  }
  .rc-tooltip-placement-right .rc-tooltip-arrow,
  .rc-tooltip-placement-rightTop .rc-tooltip-arrow,
  .rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    border-right-color: ${COLOR_INTENT.TOOLTIP.BACKGROUND};
  }
  .rc-tooltip-placement-left .rc-tooltip-arrow,
  .rc-tooltip-placement-leftTop .rc-tooltip-arrow,
  .rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    border-left-color: ${COLOR_INTENT.TOOLTIP.BACKGROUND};
  }
  .rc-tooltip-placement-bottom .rc-tooltip-arrow,
  .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    border-bottom-color: ${COLOR_INTENT.TOOLTIP.BACKGROUND};
  }
`

type Trigger = 'hover' | 'click' | 'focus'

type RcTooltipProps = React.ComponentProps<typeof RcTooltip>

interface TooltipProps extends Omit<RcTooltipProps, 'overlay' | 'children'> {
  placement?:
    | 'left'
    | 'right'
    | 'top'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'rightTop'
    | 'rightBottom'
    | 'leftTop'
    | 'leftBottom'
  trigger?: Trigger | Trigger[]
  zIndex?: number
  body: React.ReactNode
  bodyWrapperProps?: LayoutProps & SpaceProps
  isDisabled?: boolean
  align?: object | { offset: [number, number] }
}

export const Tooltip: React.FC<React.PWC<TooltipProps>> = ({
  placement = 'bottom',
  trigger = 'hover',
  zIndex = Z_INDEX.TOOLTIP,
  body,
  bodyWrapperProps = {},
  children,
  isDisabled,
  align = {
    offset: [0, 0],
  },
  ...rest
}) => {
  const wrapperRef = useRef()
  // pointer: coarse - select only devices without precise input (mouse)
  // https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer
  const isMobile = useMedia('(max-width: 768px and pointer: coarse)')

  return (
    <Wrapper ref={wrapperRef} zIndex={zIndex}>
      {isDisabled ? (
        <Box>{children}</Box>
      ) : (
        <RcTooltip
          placement={placement}
          trigger={isMobile ? 'click' : trigger}
          overlay={
            <Box maxWidth={BODY_MAX_WIDTH} {...bodyWrapperProps}>
              {body}
            </Box>
          }
          getTooltipContainer={() => wrapperRef.current}
          align={align}
          {...rest}
        >
          <Box>{children}</Box>
        </RcTooltip>
      )}
    </Wrapper>
  )
}
