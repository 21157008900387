import { type RefObject, useEffect, useRef } from 'react'
import { useIntersection } from 'react-use'
import { useGoogleAnalytics } from 'providers/googleAnalytics'
import { type MapItemToInput } from '../../types'

const THRESHOLD = 0.5 // 50% of item is visible
const ROOT_MARGIN = '0px'

interface ItemViewProps {
  itemRef: RefObject<HTMLElement>
  reportData?: Partial<MapItemToInput & { id: number; index: number }>
  onViewEvent?: (reportData: Record<string, any>) => void
  isActive?: boolean
}

export const useGAListItemView = ({
  itemRef,
  reportData = {},
  onViewEvent,
  isActive = true,
}: ItemViewProps) => {
  const eventFired = useRef(false)
  const { aggregateData, data: storedData } = useGoogleAnalytics()
  const intersection = useIntersection(itemRef, {
    root: null,
    rootMargin: ROOT_MARGIN,
    threshold: THRESHOLD,
  })
  useEffect(() => {
    if (
      isActive &&
      !eventFired.current &&
      intersection?.intersectionRatio > 0 &&
      !storedData.current?.items?.some(({ id }) => reportData.id === id)
    ) {
      if (reportData.__typename) {
        aggregateData(
          ({
            items = [],
            ...rest
          }: {
            items?: MapItemToInput[]
            [key: string]: any
          }) => ({
            ...rest,
            items: [...items, reportData],
          })
        )
      }
      onViewEvent?.(reportData)
      eventFired.current = true
    }
  }, [
    aggregateData,
    intersection,
    reportData,
    storedData,
    onViewEvent,
    isActive,
  ])

  return null
}
