import { type NextPageWithLayout } from 'next'
import React from 'react'
import GoogleAnalyticsProvider, {
  useGoogleAnalytics,
} from 'providers/googleAnalytics/googleAnalyticsProvider'

interface ProviderDataProps {
  area: string
  page_area: string
  item_list_name: string
}

export const withGA4Provider =
  (providerDataProps: Partial<ProviderDataProps>) =>
  <CP extends object>(Component: NextPageWithLayout<CP>) => {
    const WrappedComponent = <
      WCP extends React.ComponentProps<typeof Component>,
    >(
      props: WCP
    ) => {
      const GA4 = useGoogleAnalytics()

      return (
        <GoogleAnalyticsProvider parent={GA4} data={providerDataProps}>
          <Component {...props} />
        </GoogleAnalyticsProvider>
      )
    }

    WrappedComponent.getLayout = Component.getLayout
    WrappedComponent.seoProps = Component.seoProps

    return WrappedComponent
  }
