import { useState } from 'react'
import type Swiper from 'swiper'

export const useSwiperState = ({
  swiper = null,
  setSwiper = null,
}: {
  swiper?: Swiper
  setSwiper?: (swiperInstance: Swiper) => void
} = {}): [Swiper, (swiperInstance: Swiper) => void] => {
  const [newSwiper, setNewSwiper] = useState<Swiper>()

  if (!setSwiper) {
    return [newSwiper, setNewSwiper]
  }

  return [swiper, setSwiper]
}
