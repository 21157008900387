import { QA } from './QA'
import { QAMarkdown } from './QAMarkdown'
import { QAText } from './QAText'
import { Wrapper } from './QAWrapper'

QA.Text = QAText
QA.Wrapper = Wrapper
QA.Markdown = QAMarkdown

export { QA }
