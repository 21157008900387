import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-use'
import styled from 'styled-components'
import { type SpaceProps } from 'styled-system'
import { Delimiter } from 'components/Delimiter'
import Icon, { ICON_SIZE } from 'components/Icon'
import { Box, Flex } from 'components/Layout'
import { H6 } from 'components/Typography'
import { SPACE } from 'Theme'
import { type QAMarkdownProps } from './QAMarkdown'
import { type QATextProps } from './QAText'

const TRANSITION_DURATION = 0.2
const ICON_MOTION_DIV_PROPS = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: TRANSITION_DURATION },
}

const HeadingWrapper = styled(Flex)`
  cursor: pointer;
  user-select: none;
`

const IconWrapper = styled(Box)`
  position: relative;
  width: ${ICON_SIZE.PX_24};
  height: ${ICON_SIZE.PX_24};
`

const StyledMotionDiv = styled(motion.div)`
  position: absolute;
  inset: 0;
`

interface QAProps extends SpaceProps {
  title: string
  id?: string
  topBorder?: boolean
  headingComp?: React.ReactNode
  initialOpenState?: boolean
  onClick?: () => void
}

interface ExtendedQA extends React.FC<React.PWC<QAProps>> {
  Text?: React.FC<React.PWC<QATextProps>>
  Markdown?: React.FC<React.PWC<QAMarkdownProps>>
  Wrapper?: React.FC<React.PWC<unknown>>
}

export const QA: ExtendedQA = ({
  title,
  id,
  topBorder,
  children,
  onClick,
  headingComp: Heading = H6,
  initialOpenState = false,
  ...props
}) => {
  const [isOpened, setIsOpened] = useState(initialOpenState)
  const location = useLocation()

  useEffect(() => {
    if (id && location.hash?.includes(`#${id}`)) {
      setIsOpened(true)
    }
  }, [id, location.hash])

  return (
    <>
      {topBorder && <Delimiter />}
      <Box
        id={id}
        py={SPACE.PX_20}
        {...(isOpened ? { pb: SPACE.PX_30 } : {})}
        {...props}
      >
        <HeadingWrapper
          justifyContent="space-between"
          alignItems="center"
          onClick={() => {
            setIsOpened((prevValue) => !prevValue)
            onClick?.()
          }}
        >
          <Heading mb="0">{title}</Heading>
          <IconWrapper ml={SPACE.PX_20}>
            <AnimatePresence initial={false}>
              {isOpened ? (
                <StyledMotionDiv key="minus" {...ICON_MOTION_DIV_PROPS}>
                  <Icon.Minus />
                </StyledMotionDiv>
              ) : (
                <StyledMotionDiv key="plus" {...ICON_MOTION_DIV_PROPS}>
                  <Icon.Plus />
                </StyledMotionDiv>
              )}
            </AnimatePresence>
          </IconWrapper>
        </HeadingWrapper>
        <AnimatePresence initial={false}>
          {isOpened && (
            <motion.div
              initial="collapsed"
              animate="open"
              exit="collapsed"
              style={{ overflow: 'hidden' }}
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: TRANSITION_DURATION }}
            >
              <Box mt={SPACE.PX_20}>{children}</Box>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
      <Delimiter />
    </>
  )
}
