import { capitalize } from 'lodash'

const formatAmountWithUnit = (amount: number, unit: string) => {
  if (amount && unit) {
    if (amount === 1) {
      return `${amount} ${unit}`
    }
    return `${amount} ${unit}${unit === 'inch' ? 'es' : 's'}`
  }
  if (amount && !unit) {
    return `${amount}`
  }
  return ''
}

export const formatDimensionsText = ({
  length,
  width,
  height,
  managedCustomFields,
  dimensionUnit,
}: {
  length?: number
  width?: number
  height?: number
  managedCustomFields?: {
    depth?: number
  } | null
  dimensionUnit?: string
}) => {
  const valuesWithUnit = [
    {
      unit: dimensionUnit,
      // #8436 - managedCustomFields can be null so providing default value will not work
      values: { width, length, depth: managedCustomFields?.depth, height },
    },
  ]
  return valuesWithUnit.flatMap(({ unit, values }) =>
    Object.entries(values)
      .filter(([, value]) => value)
      .map(([key, value]) => ({
        key: capitalize(key),
        value: formatAmountWithUnit(value, unit),
      }))
  )
}
