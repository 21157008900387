import { remToPx, stripUnit } from 'polished'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as ReactDOMServer from 'react-dom/server'
import styled from 'styled-components'
import { space, type SpaceProps } from 'styled-system'
import type SwiperCore from 'swiper'
import { A11y, Navigation, Pagination } from 'swiper'
import 'swiper/css/bundle'
import { Swiper, type SwiperProps, SwiperSlide } from 'swiper/react'
import { type SwiperModule } from 'swiper/types'
import {
  CarouselNavigation,
  CarouselPaginationDot,
} from 'components/CarouselNavigation'
import { Box, Flex } from 'components/Layout'
import { useSwiperState } from 'hooks/useSwiperState'
import { PAGE_MAX_WIDTH, SPACE } from 'Theme'
import { useMedia } from 'useMedia'
import { SwiperArrows } from './SwiperArrows'

const LAYOUT_MAX_WIDTH = parseInt(
  stripUnit(remToPx(PAGE_MAX_WIDTH.LAYOUT)) as string
)

// The use of !important is needed to override the default style provided by swiper css
const StyledSwiper = styled(Swiper).withConfig({
  shouldForwardProp: (prop) => !['observerUpdate'].includes(prop),
})`
  ${space}

  .swiper {
    overflow: visible;
  }
  .swiper-slide {
    height: auto;
    ${({ slidesPerView }) =>
      slidesPerView === 'auto' &&
      `
        flex-shrink: 1;
        width: auto !important;
      `}
  }
  user-select: none;
`

interface CardCarouselProps
  extends Omit<SwiperProps, 'spaceBetween' | 'slidesPerView'>,
    SpaceProps {
  swiper?: SwiperCore
  setSwiper?: (swiper: SwiperCore) => void
  cards: {
    card: React.ReactNode
    key: number | string
  }[]
  isFullWidth?: boolean
  isWithPagination?: boolean
  arrowsOffsetTop?: string
  slidesPerView?: {
    MOBILE?: SwiperProps['slidesPerView']
    DESKTOP?: SwiperProps['slidesPerView']
  }
  spaceBetween?: {
    MOBILE?: SwiperProps['spaceBetween']
    DESKTOP?: SwiperProps['spaceBetween']
  }
  offset?: { MOBILE?: number; DESKTOP?: number }
  modules?: SwiperModule[]
  hasArrowsCentered?: boolean
}

const BULLET_ACTIVE_CLASS = 'card-carousel-pagination-bullet-active'

export const CardCarousel: React.FC<React.PWC<CardCarouselProps>> = ({
  cards,
  isFullWidth = false,
  isWithPagination = false,
  arrowsOffsetTop = '50%',
  offset = {
    MOBILE: 15,
    DESKTOP: 30,
  },
  spaceBetween = {
    MOBILE: 8,
    DESKTOP: 30,
  },
  slidesPerView = {
    MOBILE: 'auto',
    DESKTOP: 'auto',
  },
  swiper: receivedSwiper,
  setSwiper: setReceivedSwiper,
  modules = [],
  hasArrowsCentered = false,
  ...rest
}) => {
  const [swiper, setSwiper] = useSwiperState({
    swiper: receivedSwiper,
    setSwiper: setReceivedSwiper,
  })
  const nextButtonRef = useRef(null)
  const prevButtonRef = useRef(null)
  const paginationRef = useRef(null)
  const { MOBILE: isMobile } = useMedia()

  const responsiveSlidesPerView = isMobile
    ? slidesPerView.MOBILE
    : slidesPerView.DESKTOP

  const responsiveSlidesOffset = isMobile ? offset.MOBILE : offset.DESKTOP
  const [slidesOffset, setSlidesOffset] = useState(responsiveSlidesOffset)

  const responsiveSpaceBetween = isMobile
    ? spaceBetween.MOBILE
    : spaceBetween.DESKTOP

  const updateSlidesOffset = useCallback(
    ({ width }) => {
      setSlidesOffset(
        isFullWidth
          ? responsiveSlidesOffset
          : Math.max(
              (width - LAYOUT_MAX_WIDTH) / 2,
              responsiveSlidesOffset as number
            )
      )
    },
    [isFullWidth, responsiveSlidesOffset]
  )

  useEffect(() => {
    if (swiper) {
      updateSlidesOffset(swiper)
    }
  }, [isFullWidth, swiper, updateSlidesOffset])

  return (
    <StyledSwiper
      {...rest}
      modules={[...modules, Navigation, Pagination, A11y]}
      navigation={{
        prevEl: prevButtonRef.current,
        nextEl: nextButtonRef.current,
      }}
      pagination={{
        el: paginationRef.current,
        bulletActiveClass: BULLET_ACTIVE_CLASS,
        clickable: true,
        renderBullet: (_, className) =>
          ReactDOMServer.renderToStaticMarkup(
            <CarouselPaginationDot
              className={className}
              isAutoRotationEnabled={false}
              animationDuration={4}
              bulletActiveClass={BULLET_ACTIVE_CLASS}
              slidesCount={cards.length}
              isDarkTheme
            />
          ),
      }}
      onInit={(currentSwiperInstance) => {
        updateSlidesOffset(currentSwiperInstance)
        setSwiper(currentSwiperInstance)
      }}
      onUpdate={(currentSwiperInstance) => {
        currentSwiperInstance.navigation.update()
      }}
      onResize={(currentSwiperInstance) => {
        updateSlidesOffset(currentSwiperInstance)
        currentSwiperInstance.navigation.update()
      }}
      direction="horizontal"
      slidesPerView={responsiveSlidesPerView}
      spaceBetween={responsiveSpaceBetween}
      slidesOffsetBefore={slidesOffset}
      slidesOffsetAfter={slidesOffset}
      observer
      observerUpdate
      watchOverflow
      preventInteractionOnTransition
      threshold={15}
    >
      {cards.map(({ card, key }, index) => (
        <SwiperSlide key={key ? `slide_${key}_${index}` : index}>
          {card}
        </SwiperSlide>
      ))}
      <Box
        display={{ MOBILE: 'none', TABLET: 'block' }}
        position="absolute"
        top={0}
        width="100%"
        height="100%"
      >
        <SwiperArrows
          nextButtonRef={nextButtonRef}
          prevButtonRef={prevButtonRef}
          top={arrowsOffsetTop}
          hasArrowsCentered={hasArrowsCentered}
        />
      </Box>
      {isWithPagination && (
        <Flex
          ref={paginationRef}
          display={{ MOBILE: 'flex', TABLET: 'none' }}
          justifyContent="center"
          my={SPACE.PX_10}
        >
          <CarouselNavigation hasArrows={false} paginationRef={paginationRef} />
        </Flex>
      )}
    </StyledSwiper>
  )
}
