import { position, rem, transparentize } from 'polished'
import React from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import {
  type BackgroundProps,
  type FlexboxProps,
  type PositionProps,
  type SpaceProps,
  type LayoutProps,
  space,
  layout,
  flexbox,
  background,
} from 'styled-system'
import { Flex } from 'components/Layout'
import { Loader, LOADER_SIZE } from 'components/Loader'
import { COLOR, SPACE, Z_INDEX } from 'Theme'

export interface WrappedLoaderProps
  extends SpaceProps,
    LayoutProps,
    PositionProps,
    FlexboxProps,
    BackgroundProps,
    Pick<React.DOMAttributes<HTMLDivElement>, 'onClick'> {
  loaderSize?: (typeof LOADER_SIZE)[keyof typeof LOADER_SIZE]
  loaderColor?: (typeof COLOR)[keyof typeof COLOR]
  isOverlay?: boolean
  isFillSpace?: boolean
}

export const PAGE_MIN_HEIGHT = rem(500)

const Wrapper = styled(Flex)`
  ${({ isOverlay, isFillSpace }) =>
    (isOverlay || isFillSpace) &&
    ` width: 100%;
      height: 100%;
  `}
  ${({ isOverlay }) =>
    isOverlay &&
    ` position: absolute;
      min-height: 100vh;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: ${transparentize(0.5, COLOR.WHITE)};
      z-index: ${Z_INDEX.LOADING_OVERLAY};
    `}
  ${({ isFillSpace }) => isFillSpace && `padding: ${SPACE.PX_20};`}
  ${space};
  ${layout};
  ${flexbox};
  ${position};
  ${background};
`

export const WrappedLoader: React.FC<React.PWC<WrappedLoaderProps>> = ({
  loaderSize = LOADER_SIZE.L,
  loaderColor = COLOR.PRIMARY_1,
  isOverlay = false,
  isFillSpace = !isOverlay,
  ...rest
}) => (
  <Wrapper
    isFillSpace={isFillSpace}
    isOverlay={isOverlay}
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    {...rest}
  >
    <Loader
      size={loaderSize}
      color={loaderColor}
      data-test-id={DATA_TEST_ID.LOADER}
    />
  </Wrapper>
)
