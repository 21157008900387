import { compact, isEqual, partition, pick, sortBy, uniq } from 'lodash'
import { type NextRouter } from 'next/router'
import slugify from 'slugify'
import { type CartContextItem } from 'providers/cart'
import {
  type ExecuteDataToDataLayerProps,
  GA4_ECOM_CUSTOM_EVENTS,
  GA4_EVENTS,
} from 'providers/googleAnalytics'
import { type CartOperationTransformationContextData } from 'providers/googleAnalytics/transformations'
import {
  type ManagedCustomFields,
  type PublicProductQuery,
} from 'types/graphql-generated'
import { getProductRoute } from 'utils/getProductRoute'
import { pushUrl } from 'utils/pushUrl'

interface AddItemToCartData
  extends Omit<CartOperationTransformationContextData, 'cartItem'> {
  cartId: number
  productVariant: PublicProductQuery['product']['variants'][0]
  itemListName?: string
}

interface OnAddItemToCartTrackingInput extends AddItemToCartData {
  executeDataToDataLayer: (
    props: ExecuteDataToDataLayerProps<CartOperationTransformationContextData>
  ) => void
}

export const getVariantIdFromSlug = (slug) => {
  const [, idFromSlug] = /^[-\w]+-v(\d+)$/.exec(slug) || []
  return parseInt(idFromSlug)
}

export const getProductVariantSlug = ({ id, title }) =>
  `${slugify(title, { lower: true, strict: true })}-v${id}`

export const getProductSlug = ({ id, title }) =>
  `${slugify(title, { lower: true, strict: true })}-p${id}`

export const getVariantsQuantityUnit = ({
  managedCustomFields,
}: {
  managedCustomFields: Pick<
    ManagedCustomFields,
    'quantityUnitSingular' | 'quantityUnitPlural'
  >
}) => {
  const singular = managedCustomFields?.quantityUnitSingular
  const plural = managedCustomFields?.quantityUnitPlural
  if (!singular || !plural) {
    return null
  }
  return { singular, plural }
}

export const getVariantsMinimumOrderQuantity = ({
  managedCustomFields,
}: {
  managedCustomFields: Pick<ManagedCustomFields, 'minimumQuantity'>
}) => {
  const minimumOrderQuantity = parseInt(managedCustomFields?.minimumQuantity)
  return Number.isNaN(minimumOrderQuantity) ? 1 : minimumOrderQuantity
}

export const getVariantsLeadTime = ({
  managedCustomFields,
}: {
  managedCustomFields: Pick<ManagedCustomFields, 'leadTime'>
}): number | null => {
  const leadTime = parseInt(managedCustomFields?.leadTime)
  return leadTime > 0 ? leadTime : null
}

export const getVariantsOptions = (
  productVariant: PublicProductQuery['product']['variants'][0]
) =>
  (productVariant &&
    productVariant.optionValues.reduce(
      (options, { productOption, value }) => ({
        ...options,
        [productOption.name]: value,
      }),
      {}
    )) ||
  {}

export const isVariantsOptionUnvailable = (
  { name, value }: { name: string; value: string },
  selectedOptions: { [key: string]: string },
  variants: PublicProductQuery['product']['variants']
) =>
  !variants.some((variant) =>
    isEqual(getVariantsOptions(variant), {
      ...selectedOptions,
      [name]: value,
    })
  )

export const pushOption = (
  router: NextRouter,
  {
    optionName,
    value,
    isUnavailable,
    variants,
    selectedVariant,
    isPreview,
  }: {
    optionName: string
    value: string
    isUnavailable: boolean
    variants: PublicProductQuery['product']['variants']
    selectedVariant: PublicProductQuery['product']['variants'][0]
    isPreview?: boolean
  }
) => {
  const { query } = router
  let newSelectedVariant =
    variants.find((variant) =>
      isEqual(getVariantsOptions(variant), {
        ...getVariantsOptions(selectedVariant),
        [optionName]: value,
      })
    ) || selectedVariant

  if (isUnavailable) {
    newSelectedVariant = variants.find(
      (variant) => getVariantsOptions(variant)[optionName] === value
    )
  }
  const route = getProductRoute({
    expertSlug: query.slug as string,
    slug: query.productSlug as string,
    variantSlug: getProductVariantSlug({
      title: newSelectedVariant.title,
      id: newSelectedVariant.id,
    }),
    isPreview,
  })
  if (!route) {
    return
  }

  pushUrl({ router, route, params: pick(query, 'type') })
}

export const getValuesForOption = (
  optionName: string,
  variants: PublicProductQuery['product']['variants']
) =>
  compact(
    uniq(
      variants.map(
        ({ optionValues }) =>
          optionValues.find(
            ({ productOption }) => productOption.name === optionName
          )?.value
      )
    )
  )

export const sortProductVariantsByPriceAndQuantity = (
  variants: PublicProductQuery['product']['variants']
): PublicProductQuery['product']['variants'] => {
  const [availableVariants, notAvailableVariants] = partition(
    variants,
    ({ quantity, managedCustomFields }) => {
      const leadTimeWeeks = Number(managedCustomFields?.leadTime) || 0
      return quantity > 0 || leadTimeWeeks > 0
    }
  ).map((partitionedVariants) => sortBy(partitionedVariants, 'price.total'))
  return [...availableVariants, ...notAvailableVariants]
}

export const getProductIdFromSlug = (slug: string) => {
  const [, idFromSlug] = /^[-\w]+-p(\d+)$/.exec(slug) || []
  return parseInt(idFromSlug)
}

export const onAddItemToCartTracking = ({
  executeDataToDataLayer,
  quantity,
  cartId,
  productVariant,
  itemListName,
}: OnAddItemToCartTrackingInput) => {
  executeDataToDataLayer({
    event: GA4_EVENTS.ADD_TO_CART,
    overrideData: {
      cartItem: {
        cartId,
        productVariant,
      } as unknown as CartContextItem,
      quantity,
      itemListName,
    },
  })

  executeDataToDataLayer({
    event: GA4_ECOM_CUSTOM_EVENTS.ADD_TO_CART_SHOWROOM,
    includeStoredData: false,
  })
}
