import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import Icon, { ICON_SIZE } from 'components/Icon'
import { Flex } from 'components/Layout'
import { COLOR_INTENT, Z_INDEX } from 'Theme'

type CarouselNavigationArrowProps = {
  isLtr: boolean
  isDarkTheme: boolean
  hasBackground?: boolean
  ariaLabel?: string
  onClick?: () => void
  className?: string
}

const ARROW_WITHOUT_BACKGROUND_WRAPPER_SIZE = rem(44)
const ARROW_WITH_BACKGROUND_WRAPPER_WIDTH = rem(40)
export const ARROW_WITH_BACKGROUND_WRAPPER_HEIGHT = rem(80)
const WRAPPER_BACKGROUND_BORDER_RADIUS = rem(50)

export const getArrowStickySide = ({
  isStickyToLeft,
}: {
  isStickyToLeft: boolean
}) =>
  isStickyToLeft
    ? {
        left: 0,
      }
    : { right: 0 }

export const DefaultArrowWrapper = styled(Flex)`
  position: absolute;
  height: fit-content;
  width: fit-content;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
  z-index: ${Z_INDEX.CAROUSEL};
  ${({ isStickyToLeft }) => getArrowStickySide({ isStickyToLeft })}
`

const ARROW_WITHOUT_BG_STYLES = {
  'min-width': ARROW_WITHOUT_BACKGROUND_WRAPPER_SIZE,
  width: ARROW_WITHOUT_BACKGROUND_WRAPPER_SIZE,
  height: ARROW_WITHOUT_BACKGROUND_WRAPPER_SIZE,
  'background-color': 'transparent',
}

const ARROW_WITH_BG_STYLES = {
  'min-width': ARROW_WITH_BACKGROUND_WRAPPER_WIDTH,
  width: ARROW_WITH_BACKGROUND_WRAPPER_WIDTH,
  height: ARROW_WITH_BACKGROUND_WRAPPER_HEIGHT,
  'background-color': COLOR_INTENT.CAROUSEL_NAVIGATION.ARROWS.BG_COLOR,
}

const getBorderRadius = ({
  withBackground,
  isLtr,
}: {
  withBackground: boolean
  isLtr: boolean
}) => {
  if (withBackground) {
    return `border-radius: ${
      isLtr
        ? `${WRAPPER_BACKGROUND_BORDER_RADIUS} 0 0 ${WRAPPER_BACKGROUND_BORDER_RADIUS}`
        : `0 ${WRAPPER_BACKGROUND_BORDER_RADIUS} ${WRAPPER_BACKGROUND_BORDER_RADIUS} 0`
    }`
  }
  return {}
}

const getJustifyContent = ({
  withBackground,
  isLtr,
}: {
  withBackground: boolean
  isLtr: boolean
}) => {
  if (withBackground) {
    return `justify-content:  ${isLtr ? 'end' : 'start'}`
  }
  return { 'justify-content': 'center' }
}

export const ArrowWrapper = styled(Flex).attrs(() => ({
  as: 'button',
}))<{ withBackground?: boolean; isDarkTheme: boolean; isLtr: boolean }>`
  ${({ withBackground, isLtr }) =>
    getJustifyContent({ withBackground, isLtr })};
  align-items: center;
  pointer-events: all;
  cursor: pointer;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  ${({ withBackground }) =>
    withBackground
      ? { ...ARROW_WITH_BG_STYLES }
      : { ...ARROW_WITHOUT_BG_STYLES }};
  ${({ withBackground, isLtr }) => getBorderRadius({ withBackground, isLtr })};
`

const COMMON_ARROW_PROPS = {
  color: COLOR_INTENT.CAROUSEL_NAVIGATION.ARROWS.ARROW_COLOR,
  size: ICON_SIZE.PX_24,
  hoveringColor: COLOR_INTENT.CAROUSEL_NAVIGATION.ARROWS.ARROW_COLOR_HOVER,
}

export const CarouselNavigationArrow = React.forwardRef<
  HTMLButtonElement,
  CarouselNavigationArrowProps
>(({ isLtr, hasBackground = false, ariaLabel, onClick, ...rest }, ref) => (
  <ArrowWrapper
    ref={ref}
    aria-label={ariaLabel ?? `${isLtr ? 'next' : 'previous'} arrow button`}
    withBackground={hasBackground}
    isLtr={isLtr}
    {...(onClick && { onClick })}
    {...rest}
  >
    {isLtr ? (
      <Icon.CarouselArrowRight {...COMMON_ARROW_PROPS} />
    ) : (
      <Icon.CarouselArrowLeft {...COMMON_ARROW_PROPS} />
    )}
  </ArrowWrapper>
))
