import { type NextRouter } from 'next/router'
import queryString from 'query-string'

export interface SilentPushUrl {
  router: NextRouter
  route: string
  anchor?: string
  params?: Record<string, any>
}

interface PushUrl extends SilentPushUrl {
  shallow?: boolean
}

export const pushUrl = ({
  router,
  route,
  params,
  anchor,
  shallow = false,
}: PushUrl) => {
  const { url, query } = queryString.parseUrl(route)
  const formattedParams = queryString.stringify({
    ...query,
    ...params,
  })
  router.replace(
    `${url}${formattedParams && `?${formattedParams}`}${
      anchor ? `#${anchor}` : ''
    }`,
    undefined,
    {
      shallow,
    }
  )
}

export const silentPushUrl = ({
  router,
  route,
  anchor,
  params,
}: SilentPushUrl) => pushUrl({ router, route, anchor, params, shallow: true })
