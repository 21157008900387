import styled from 'styled-components'
import { Box } from 'components/Layout'
import { FONT_SIZE, FONT_STACK } from 'Theme'

export const Wrapper = styled(Box)`
  * {
    font-family: ${FONT_STACK.SERIF};
    font-size: ${FONT_SIZE.PX_16};
  }
`
