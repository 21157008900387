import { gql, useQuery } from '@apollo/client'
import get from 'lodash/get'

const COUPONS_SETTINGS_QUERY = gql`
  query couponsSettings {
    couponsSettings {
      isDiscountEnabled
    }
  }
`

export const useCouponsSettingsQuery = (options) => {
  const { data, ...rest } = useQuery(COUPONS_SETTINGS_QUERY, {
    fetchPolicy: 'cache-first',
    ...options,
  })
  return {
    couponsSettings: get(data, ['couponsSettings'], {}),
    ...rest,
  }
}
