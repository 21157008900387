import PropTypes from 'prop-types'
import React from 'react'
import Markdown from 'react-markdown' // Cannot be updated because of issue with trailing spaces in markdown - https://github.com/sudolabs-io/the-expert/issues/3519
import sanitizeHtml from 'sanitize-html'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Text } from 'components/Typography'
import { FONT_SIZE, FONT_STACK } from 'Theme'

/**
 * @todo remove when this is solved
 * https://github.com/sudolabs-io/the-expert/issues/3077
 */
export const MARKDOWN_UNDERLINE_REGEX = /(\+\+)((?:(?=(\\?))\3.)*?)\1/gs

const StyledMarkdown = styled(Markdown)`
  h1 {
    font-size: ${FONT_SIZE.PX_48};
  }
  h2 {
    font-size: ${FONT_SIZE.PX_36};
  }
  h3 {
    font-size: ${FONT_SIZE.PX_32};
  }
  h4 {
    font-size: ${FONT_SIZE.PX_24};
  }
  h5 {
    font-size: ${FONT_SIZE.PX_20};
  }
  h6 {
    font-size: ${FONT_SIZE.PX_16};
  }
  a {
    text-decoration: none;
  }
`

export const addUnderlineSupport = (text) =>
  text.replace(MARKDOWN_UNDERLINE_REGEX, '<u>$2</u>')

export const MarkdownRenderer = ({ markdownSource, ...props }) => (
  <Text
    data-test-class={DATA_TEST_CLASS.MARKDOWN_RENDERER}
    fontFamily={FONT_STACK.SERIF}
    {...props}
  >
    <StyledMarkdown
      source={addUnderlineSupport(sanitizeHtml(markdownSource))}
      escapeHtml={false}
      linkTarget="_blank"
    />
  </Text>
)

MarkdownRenderer.propTypes = {
  markdownSource: PropTypes.string.isRequired,
}
