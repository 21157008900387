import {
  GA4_EVENTS,
  GA4_PARAMS,
  useGoogleAnalytics,
} from 'providers/googleAnalytics'
import { LIST_ENTITY_TYPE, useItemListStore } from 'providers/useItemListStore'
import {
  type Brand,
  type ManagedCustomFields,
  type Product,
  type ProductCategory,
  type ProductVariant,
} from 'types/graphql-generated'

type OptionValue = ProductVariant['optionValues'][0]

export type VariantsType = Pick<
  ProductVariant,
  'id' | 'sku' | 'quantity' | 'status'
> & {
  price: Pick<
    ProductVariant['price'],
    'total' | 'currencyIsoCode' | 'base' | 'vatRate'
  >
  tradePrice?: Pick<
    ProductVariant['tradePrice'],
    'total' | 'currencyIsoCode' | 'base' | 'vatRate'
  >
  product: Pick<Product, 'title' | 'id'> & {
    categoryOne?: Pick<ProductCategory, 'name'>
    categoryTwo?: Pick<ProductCategory, 'name'>
    categoryThree?: Pick<ProductCategory, 'name'>
    brand?: Pick<Brand, 'id'>
  } & Partial<Pick<Product, 'slug'>>
  managedCustomFields?: Pick<ManagedCustomFields, 'leadTime'>
  optionValues?: {
    value?: OptionValue['value']
    productOption: Pick<OptionValue['productOption'], 'name'>
  }[]
} & Partial<
    Pick<ProductVariant, 'images' | 'slug' | 'carouselImage' | 'leadImage'>
  >

export const useHandleCollectionWidgetCardClick = (
  index: number,
  variants?: VariantsType[],
  onClick?: () => void
) => {
  const { executeDataToDataLayer, getPropertyInTree } = useGoogleAnalytics()
  const { setItemListData } = useItemListStore()

  return () => {
    onClick?.()
    if (variants?.length) {
      setItemListData(
        {
          entityId: variants[0].product.id,
          entityType: LIST_ENTITY_TYPE.PRODUCT,
        },
        {
          itemListName: getPropertyInTree(GA4_PARAMS.ITEM_LIST_NAME),
          index,
        }
      )
      executeDataToDataLayer({
        event: GA4_EVENTS.SELECT_ITEM,
        overrideData: {
          items: [
            {
              index,
              ...variants[0],
            },
          ],
        },
      })
    }
  }
}
